<template>
  <v-select
    label='Language'
    :items="langs"
    item-text="name"
    item-value="value"
    v-model="inputVal"
  ></v-select>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: ["callbackSelect", "callbackError","value"],
  data: () => ({
    langs: [
      {
        name: "Bahasa Melayu",
        value: "ms",
      },
      {
        name: "中文",
        value: "zh",
      },
      {
        name: "English",
        value: "en",
      },
    ],
  }),
  created() {
 
  },
   computed: {
    ...mapState({
      school: (state) => state.school.data,
    }),
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
        this.callbackSelect(val)
      },
    },
  },
  methods: {
   
  },
  mounted() {
    
  },
};
</script>
